<script lang="ts" setup>
import { ZIndexDropDown } from '@/variables/z-indexes';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue';
import { Fn, onClickOutside, useEventListener } from '@vueuse/core';
import { blurActiveElement, createUuId } from '@/util/globals';
import { useEmitStore } from '@/store/EmitStore';
import VList, { type ListItem } from '@/components/VList.vue';
import { twMerge } from 'tailwind-merge';
import VButton, { type ButtonProps } from '@/components/Inputs/VButton.vue';

export type DropDownListItem = ListItem & {
  action?: (close: () => void) => void;
};

type Props = {
  buttonTitle?: ButtonProps['title'];
  canOpenDropdown?: boolean;
  items?: DropDownListItem[];
  itemKey?: string;
  itemLabel?: string;
  tabindex?: number | null;
  closeOnClick?: boolean;
  setFocus?: boolean;
  zIndexOfDropdown?: number;
  maxHeightDropdown?: string;
  wrapperClasses?: string | null;
  pageXModifier?: number;
  pageYModifier?: number;
  haveMaxWidth?: boolean;
  targetClasses?: string | null;
  withFlexOne?: boolean;
  closeOnScroll?: boolean;
  closeOnEsc?: boolean;
  shouldClose?: boolean;
  openDropdownFromOutside?: boolean;
  highlightText?: string | null;
  withArrowsUpAndDown?: boolean | null;
  openOnHover?: boolean;
  dataTheme?: 'base' | 'light';
  dropUp?: boolean;
  dropSide?: 'right' | 'left' | 'center';
  closeOnHoverAway?: boolean;
  addZindex?: number;
  anchorPositionX?: 'left' | 'right';
  withBackdrop?: boolean;
  minWidth?: string;
};

const props = withDefaults(defineProps<Props>(), {
  buttonTitle: null,
  canOpenDropdown: true,
  items: () => [],
  itemKey: 'id',
  itemLabel: 'name',
  closeOnClick: true,
  setFocus: false,
  zIndexOfDropdown: ZIndexDropDown,
  maxHeightDropdown: '40vh',
  wrapperClasses: null,
  targetClasses: null,
  tabindex: undefined,
  tabindex: null,
  pageYModifier: 0,
  pageXModifier: 0,
  haveMaxWidth: true,
  withFlexOne: true,
  closeOnScroll: true,
  closeOnEsc: true,
  shouldClose: true,
  openDropdownFromOutside: true,
  highlightText: null,
  withArrowsUpAndDown: false,
  openOnHover: false,
  dataTheme: undefined,
  dropUp: false,
  dropSide: 'center',
  closeOnHoverAway: false,
  addZindex: 0,
  anchorPositionX: 'left',
  withBackdrop: true,
  minWidth: undefined,
});

const emit = defineEmits<{
  (e: 'dropdownOpened'): void;
  (e: 'dropdownClosed'): void;
}>();

const uuid = createUuId();

const { rootEmit } = useEmitStore();

const show = defineModel<boolean>({ default: false });

const dropdownMounted = ref(false);

const wrapper = useTemplateRef<HTMLDivElement>('wrapper');
const target = useTemplateRef<HTMLDivElement>('target');

const anchorId = createUuId('--anchor_');

const topY = ref('0px');
const left = ref('0px');

const calculatedMaxHeightDropdown = ref(props.maxHeightDropdown);
const minWidth = ref(props.minWidth ?? '100px');

const calcHeight = (numberOfItems: number, itemsHeight = 29) => {
  // let height = numberOfItems * itemsHeight;
  // height = height + 35;
  // if (props.maxHeightDropdown.includes('vh')) {
  //   const vh = window.innerHeight;
  //   const percent = props.maxHeightDropdown.replace('vh', '');
  //   const px = (vh * parseInt(percent, 10)) / 100;
  //
  //   if (px < height) {
  //     calcHeight(numberOfItems - 1);
  //   } else {
  //     calculatedMaxHeightDropdown.value = `${height}px`;
  //   }
  // } else {
  //   const px = props.maxHeightDropdown.replace('px', '');
  //   if (parseInt(px, 10) < height) {
  //     calcHeight(numberOfItems - 1);
  //   } else {
  //     calculatedMaxHeightDropdown.value = `${height}px`;
  //   }
  // }
};

onMounted(async () => {
  await nextTick();
  // if (props.items?.length) {
  //   calcHeight(props.items.length);
  // }
});

watch(show, () => {
  if (show.value) {
    setTimeout(() => {
      const liItems = target.value?.querySelectorAll('li');
      const first = liItems?.[0];
      const height = first?.clientHeight;
      // if (props.items?.length) {
      //   calcHeight(props.items.length, height);
      // }
    }, 50);
  }
});

// watch(
//   () => props.items,
//   () => {
//     if (props.items?.length) {
//       calcHeight(props.items.length);
//     }
//   }
// );

if (!props.withBackdrop) {
  onClickOutside(target, (event) => {
    if (!wrapper.value || !event.target) return;

    if (wrapper.value.contains(event.target)) return;

    closeDropdown();
  });
}

const zIndex = ref(props.zIndexOfDropdown + props.addZindex);

const findParentDialog = (element: HTMLDivElement) => {
  do {
    if (element.getAttribute('role') === 'dialog') {
      return element; // Return the parent with role="dialog"
    }
    element = element.parentElement; // Move up to the parent element
  } while (element);

  return null; // Return null if no parent with role="dialog" was found
};

const supportsAnchor = CSS.supports('anchor-name', anchorId);
const supportsAnimateHeight = CSS.supports('interpolate-size', 'allow-keywords');

const setPositionLegacy = async (wrapperRect: DOMRect, dropdownRect: DOMRect, overflow: number) => {
  if (!wrapperRect || !dropdownRect) {
    console.error('setPositionLegacy');
    return;
  }
  if (!props.minWidth) {
    minWidth.value = `${wrapperRect.width}px`;
  }

  let tempLeft = 0;
  if (props.dropSide === 'center') {
    tempLeft = wrapperRect.left + props.pageXModifier;
  } else if (props.dropSide === 'right') {
    // left.value = wrapperRect.right;
  } else if (props.dropSide === 'left') {
    // left.value = wrapperRect.left - dropdownRect.width;
  }

  if (overflow > 0) {
    tempLeft -= overflow + 5;
  }

  if (document.body.clientWidth <= (dropdownRect.width ?? 0)) {
    tempLeft = 0;
  }

  left.value = `${tempLeft}px`;

  let tempX = 0;

  tempX = wrapperRect.bottom + props.pageYModifier;

  await nextTick();

  if (target.value) {
    const dropdownRect = target.value?.getBoundingClientRect();
    const h = wrapperRect.bottom + dropdownRect.height + props.pageYModifier;
    if (dropdownRect && h > window.innerHeight) {
      tempX = wrapperRect.top - dropdownRect.height;
    }
    topY.value = `${tempX}px`;
  }
  topY.value = `${tempX}px`;
};

const setPositionAnchor = async (wrapperRect: DOMRect, dropdownRect: DOMRect) => {
  if (!wrapperRect || !dropdownRect) {
    console.error('setPositionAnchor');
    return;
  }
  if (!props.minWidth) {
    minWidth.value = `${wrapperRect.width}px`;
  }
  // if (props.dropSide === 'center') {
  //   left.value = wrapperRect.left + props.pageXModifier;
  // } else if (props.dropSide === 'right') {
  //   // left.value = wrapperRect.right;
  // } else if (props.dropSide === 'left') {
  //   // left.value = wrapperRect.left - dropdownRect.width;
  // }
  left.value = `anchor(${props.anchorPositionX})`;
  if (wrapperRect.left + (dropdownRect?.width ?? 0) > document.body.clientWidth && props.anchorPositionX === 'left') {
    const minusPx = wrapperRect.left + (dropdownRect?.width ?? 0) - document.body.clientWidth;
    left.value = `calc(anchor(${props.anchorPositionX}) - ${minusPx + 4}px);`;
  }

  if (dropdownRect.height + wrapperRect.bottom > document.body.clientHeight) {
    if (wrapperRect.top - dropdownRect.height < 0) {
      if (!props.maxHeightDropdown.includes('vh')) {
        const tempH = dropdownRect.height + wrapperRect.bottom - document.body.clientHeight;
        calculatedMaxHeightDropdown.value = `${dropdownRect.height - tempH - 2}px`;
      }
    } else {
      topY.value = `calc(anchor(top) - ${dropdownRect.height + 4}px);`;
    }
  } else {
    topY.value = 'anchor(bottom)';
  }
};

const openDropdown = async () => {
  if (!props.canOpenDropdown) return;

  show.value = true;
  await nextTick();
  dropdownMounted.value = true;

  if (!wrapper.value) throw Error('No dropdown wrapper');

  const parentDiv = findParentDialog(wrapper.value);
  if (parentDiv) {
    const t = window.getComputedStyle(parentDiv);
    zIndex.value = Number(t.getPropertyValue('z-index')) + props.addZindex;
  }

  let overflow = 0;
  const wrapperRect = wrapper.value.getBoundingClientRect();
  let dropdownRect;
  if (!target.value) throw Error('No dropdown body');

  dropdownRect = target.value?.getBoundingClientRect();
  if (document.body.clientWidth > dropdownRect?.width) {
    overflow = wrapperRect.left + dropdownRect.width - document.body.clientWidth;
  }

  if (supportsAnchor) {
    await setPositionAnchor(wrapperRect, dropdownRect);
    setTimeout(() => {
      const temp = target.value?.getBoundingClientRect();
      if (temp) {
        setPositionAnchor(wrapperRect, temp);
      }
    }, 300);
  } else {
    await setPositionLegacy(wrapperRect, dropdownRect, overflow);
  }
  emit('dropdownOpened');
};

const resize_ob = new ResizeObserver(function (entries) {
  // since we are observing only a single element, so we access the first element in entries array
  let rect = entries[0].contentRect;

  // current width & height
  let height = rect.height;

  // console.log('Current Height : ' + height);
  const dropdownRect = target.value?.getBoundingClientRect();
  const wrapperRect = wrapper.value?.getBoundingClientRect();

  if (!dropdownRect || !wrapperRect) return;

  if (dropdownRect.height + wrapperRect.bottom > document.body.clientHeight) {
    if (wrapperRect.top - dropdownRect.height < 0) {
      if (!props.maxHeightDropdown.includes('vh')) {
        const tempH = dropdownRect.height + wrapperRect.bottom - document.body.clientHeight;
        calculatedMaxHeightDropdown.value = `${height - tempH - 2}px`;
      }
    } else {
      topY.value = `calc(anchor(top) - ${height + 4}px);`;
    }
  } else {
    topY.value = 'anchor(bottom)';
  }
});

if (supportsAnchor) {
  watch(dropdownMounted, (v) => {
    if (v && target.value) {
      resize_ob.observe(target.value);
      return;
    }
    resize_ob.disconnect();
  });
}

const closeDropdown = async () => {
  dropdownMounted.value = false;
  setTimeout(() => {
    show.value = false;
    emit('dropdownClosed');
  }, 201);
};

const scrollLisetener = ref<Fn | null>(null);

watch(
  () => props.closeOnScroll,
  (newValue) => {
    if (newValue && show.value) {
      scrollLisetener.value = useEventListener(
        'wheel',
        (event) => {
          if (!target.value?.contains(event.target as Node)) {
            show.value = false;
            emit('dropdownClosed');
          }
        },
        { passive: true }
      );
    } else {
      scrollLisetener.value?.();
    }
  }
);

watch(show, () => {
  if (!show.value) {
    scrollLisetener.value?.();
    emit('dropdownClosed');
  }
  if (show.value && props.closeOnScroll) {
    scrollLisetener.value = useEventListener(
      'wheel',
      (event) => {
        if (!target.value?.contains(event.target as Node)) {
          show.value = false;
          emit('dropdownClosed');
        }
      },
      { passive: true }
    );
  }
});

const handleTab = async (event) => {
  if (event.key === 'Escape' && show.value) {
    show.value = false;
    return;
  }
  setTimeout(async () => {
    if (event.keyCode !== 9) return;
    if (show.value) {
      show.value = false;
      return;
    }
    if (document.activeElement === wrapper.value) {
      event.preventDefault();
      await nextTick();
      toggle();
    }
  }, 10);
};

onBeforeUnmount(async () => {
  document.removeEventListener('keydown', handleTab);
});

onMounted(async () => {
  await nextTick();
  if (props.setFocus) {
    blurActiveElement();
    setTimeout(() => {
      toggle();
    }, 400);
  }
  document.addEventListener('keydown', handleTab);
});

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'close-all-drop-downs': {
      show.value = false;
      break;
    }
    default:
      break;
  }
});
watch(
  () => props.openDropdownFromOutside,
  () => {
    if (props.openDropdownFromOutside) {
      toggle();
    }
  }
);

const onHover = () => {
  if (!props.openOnHover) return;
  rootEmit('close-other-dropdowns-on-hover', uuid);
  toggle();
};

const mouseLeave = () => {
  if (!props.openOnHover || !props.closeOnHoverAway) return;
  closeDropdown();
};

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'close-other-dropdowns-on-hover': {
      if (state.item?.payload !== uuid && props.openOnHover) {
        closeDropdown();
      }
      break;
    }
    default:
      break;
  }
});

const toggle = () => {
  if (show.value && props.closeOnClick) {
    closeDropdown();
    return;
  }
  openDropdown();
};

defineSlots<{
  'click-area'?: (props: {
    close: (item?: unknown, event?: MouseEvent, force?: boolean) => void;
    open: () => void;
    toggle: () => void;
    state: boolean;
    'is-open': boolean;
    isOpen: boolean;
  }) => any;
  'aboveDropdown': () => any;
  'title': () => any;
  'pre': (props: { item: ListItem }) => any;
  'dropdown': (props: { close: (item?: unknown, event?: MouseEvent, force?: boolean) => void }) => any;
}>();

const isAnchor = computed(() => !left.value?.includes('px'));

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <div
    ref="wrapper"
    :class="wrapperClasses"
    class="anchor cursor-pointer"
    :style="`anchor-name: ${anchorId}`"
    :data-click-id="uuid"
    v-bind="$attrs"
    @mouseleave="mouseLeave"
    @mouseenter="onHover"
    @click.stop="toggle">
    <slot
      name="click-area"
      :close="closeDropdown"
      :open="openDropdown"
      :toggle="toggle"
      :state="show"
      :is-open="show">
      <VButton :title="buttonTitle" />
    </slot>
  </div>

  <teleport to="body">
    <div
      v-if="show && !openOnHover && withBackdrop"
      class="fixed left-0 top-0 h-dvh w-dvw bg-transparent"
      :style="`z-index: ${zIndex + 1};`"
      @click="closeDropdown()" />

    <div
      v-if="show"
      ref="target"
      :style="`${isAnchor ? `${anchorPositionX}: ${left};` : `left: ${left};`}; min-width: ${minWidth}; top: ${topY};  z-index: ${zIndex + 1}; position-anchor: ${anchorId};  ${supportsAnimateHeight ? 'transition: height 0.2s ease, visibility 0.2s;' : ''}`"
      :data-theme="dataTheme"
      :class="
        twMerge(
          'mt-1 overflow-hidden rounded border bg-[--color-background-dropdown] shadow-2xl',
          haveMaxWidth && 'max-w-[500px]',
          targetClasses,
          supportsAnchor ? 'target' : '',
          `fixed`,
          supportsAnimateHeight
            ? dropdownMounted
              ? 'visible h-auto min-h-[20px]'
              : 'invisible h-0'
            : dropdownMounted
              ? 'h-auto'
              : 'invisible h-0'
        )
      ">
      <slot name="aboveDropdown" />
      <div
        :style="`max-height: ${calculatedMaxHeightDropdown};`"
        class="overflow-auto">
        <slot
          :close="closeDropdown"
          name="dropdown">
          <div class="dropdown-container">
            <VList
              v-if="items?.length"
              :highlight-text="highlightText"
              :with-arrows-up-and-down="withArrowsUpAndDown"
              :items="
                items.map((item) => ({
                  ...item,
                  items:
                    item?.items?.map((subItem) => ({
                      ...subItem,
                      action: subItem.action
                        ? () => {
                            subItem.action(closeDropdown);
                          }
                        : undefined,
                    })) ?? [],
                  action: item.action
                    ? () => {
                        item.action(closeDropdown);
                      }
                    : undefined,
                  postIcon:
                    typeof item.postIcon === 'object'
                      ? {
                          ...item.postIcon,
                          action: () => {
                            item.postIcon?.action(closeDropdown());
                          },
                        }
                      : item.postIcon,
                }))
              ">
              <template
                v-if="$slots.pre"
                #pre="{ item }">
                <slot
                  v-if="item.type !== 'header'"
                  name="pre"
                  :item="item" />
              </template>
            </VList>
          </div>
        </slot>
      </div>
      <slot name="underDropdown" />
    </div>
  </teleport>
</template>
