import { usePage } from '@inertiajs/vue3';
import { useLocalStorage } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, onBeforeUnmount, ref } from 'vue';

type THEME = 'base' | 'light';

export const useUserStore = defineStore('user', () => {
  const initTheme = (localStorage.getItem('theme') ?? 'base') as THEME;

  const THEMES_TOP_LEFT = ['base', 'light'] as const;

  const initThemeTopLeft = (localStorage.getItem('theme_top_left') ?? 'base') as (typeof THEMES_TOP_LEFT)[number];

  const userId = ref<number | null>(null);
  const name = ref('');
  const admin = ref(false);
  const email = ref('');
  const customHomeId = ref<number | null>(null);
  const customHomeType = ref<string | null>(null);
  const notifications = ref([]);
  const intro = ref<(() => void) | null>(null);
  const theme = useLocalStorage<THEME>('theme', initTheme ?? 'base');
  const themeTopLeft = useLocalStorage<(typeof THEMES_TOP_LEFT)[number]>('theme_top_left', initThemeTopLeft ?? 'base');

  const isControlDown = ref(false);

  // onMounted(() => {
  //   Track the state of the Ctrl key
  const keyDownControl = function (event) {
    if (event.key === 'Control') {
      isControlDown.value = true;
    }
  };
  document.addEventListener('keydown', keyDownControl);

  const keyUpControl = function (event) {
    if (event.key === 'Control') {
      isControlDown.value = false;
    }
  };
  document.addEventListener('keyup', keyUpControl);
  onBeforeUnmount(() => {
    document.removeEventListener(keyUpControl);
    document.removeEventListener(keyDownControl);
  });

  const notificationCount = computed(() => notifications.value.length);

  const fill = (user: any = null) => {
    if (usePage().props.authenticated) {
      if (user?.id) {
        userId.value = user.id;
        name.value = user.name;
        admin.value = user.is_admin;
        email.value = user.email;
        customHomeId.value = user.custome_home_id;
        customHomeType.value = user.custome_home_typ;
      } else {
        userId.value = usePage().props.auth.user.id;
        name.value = usePage().props.auth.user.name;
        admin.value = usePage().props.auth.user.is_admin;
        email.value = usePage().props.auth.user.email;
        customHomeId.value = usePage().props.auth.user.custom_home_id;
        customHomeType.value = usePage().props.auth.user.custom_home_type;
      }
    }
  };

  return {
    userId,
    name,
    admin,
    email,
    customHomeId,
    customHomeType,
    fill,
    notificationCount,
    theme,
    intro,
    themeTopLeft,
    THEMES_TOP_LEFT,
    isControlDown,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
