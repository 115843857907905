<script setup lang="ts">
import { ref } from 'vue';
import { InviteResource } from '@/types/invite';
import ContentList from '@/components/Content/ContentList.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import {
  compareTwo,
  formatStampAsDate,
  formatStampAsHumanReadableDate,
  formattedStartAndEndAsInterval,
} from '@/util/timeFunctions';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import { humanReadableWeekDay } from '@/variables/date-format';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import NameAvatar from '@/components/NameAvatar.vue';
import { concatName } from '@/services/api-partners';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { getComponent } from '@/util/get-component';
import GridTemplate from '@/components/GridTemplate.vue';
import { allAvailableFieldTypes, multiSelectFieldType, tableFieldType } from '@/util/fields';

type PerformanceForInfoAccessPerformanceInfoFieldResource = {
  id: number;
  title: string;
  component: string;
  options: [] | null;
  description: string | null;
  class: string | null;
  order: number | null;
  editable: boolean;
  value: any | null;
  meta: {} | null;
};
type PerformanceForInfoAccessResource = {
  id: number;
  name: string;
  owner_id: number;
  owner_type: string;
  itinerary:
    | null
    | {
        id: number;
        type: string;
        title: string;
        start: string;
        end: string;
      }[];
  contacts:
    | null
    | {
        id: number;
        first_name: string;
        last_name: string;
        phone: string | null;
        email: string | null;
        country_code: string | null;
        role: string | null;
        performance_partner_type_id: string | null;
      }[];
  performance_info_sections:
    | null
    | {
        id: number;
        title: string;
        owner_id: number;
        owner_type: string;
        performance_info_fields: PerformanceForInfoAccessPerformanceInfoFieldResource[];
      }[];
};

type Props = {
  invite: InviteResource;
  groupId: number;
  canEdit: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const fetchingData = ref(true);
const performances = ref<PerformanceForInfoAccessResource[]>([]);

const fetchItAll = async () => {
  fetchingData.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/performance-info-accesses/`);
  performances.value = data;
  fetchingData.value = false;
};
fetchItAll();

const performanceItinerary = (performance) => {
  if (!performance.itinerary) return null;
  const groupedItems = performance.itinerary.sort((a, b) => compareTwo(a, b, 'start'));
  return _.groupBy(groupedItems, (item) => formatStampAsDate(item.start));
};
const getItemIcon = (item) => {
  switch (item.type) {
    case 'App\\ShowTime': {
      return 'fa-volume-up';
    }
    case 'App\\Models\\LocalTravels\\LocalTravel': {
      return 'fa-car';
    }
  }
  return 'fa-list';
};

const assignValue = async (
  value,
  performanceId: number,
  field: PerformanceForInfoAccessPerformanceInfoFieldResource,
  sectionId: number
) => {
  if (!field.editable) return;
  if (field.performance_info_access_id) {
    await axios.post(`/api/invites/${props.invite.id}/performance-info-accesses/`, {
      performance_id: performanceId,
      performance_info_section_id: sectionId,
      performance_info_field_id: field.id,
      value: value,
    });
  } else {
    await axios.post(`/api/performances/${performanceId}/performance-info-fields/${field.id}`, {
      value,
    });
  }
  const performanceIndex = getIndexFromArrayBasedOnId(performanceId, performances.value);
  if (performanceIndex === -1) return;
  const sectionIndex = getIndexFromArrayBasedOnId(
    sectionId,
    performances.value[performanceIndex].performance_info_sections
  );
  if (sectionIndex === -1) return;
  const fieldIndex = getIndexFromArrayBasedOnId(
    field.id,
    performances.value[performanceIndex].performance_info_sections[sectionIndex].performance_info_fields
  );
  if (fieldIndex === -1) return;
  performances.value[performanceIndex].performance_info_sections[sectionIndex].performance_info_fields[
    fieldIndex
  ].value = value;

  if (field.component === 'field-document' && !value) {
    performances.value[performanceIndex].performance_info_sections[sectionIndex].performance_info_fields[
      fieldIndex
    ].meta = null;
  }
};
const documentUploaded = async (performanceId: number, sectionId: number, fieldId: number, doc) => {
  const performanceIndex = getIndexFromArrayBasedOnId(performanceId, performances.value);
  if (performanceIndex === -1) return;
  const sectionIndex = getIndexFromArrayBasedOnId(
    sectionId,
    performances.value[performanceIndex].performance_info_sections
  );
  if (sectionIndex === -1) return;
  const fieldIndex = getIndexFromArrayBasedOnId(
    fieldId,
    performances.value[performanceIndex].performance_info_sections[sectionIndex].performance_info_fields
  );
  if (fieldIndex === -1) return;
  performances.value[performanceIndex].performance_info_sections[sectionIndex].performance_info_fields[
    fieldIndex
  ].meta = doc;
};

const getProps = (field: PerformanceForInfoAccessPerformanceInfoFieldResource, performanceId) => {
  const defaultProps = {
    canEdit: field.editable,
    'model-value': getKey(field, 'value', null),
  };

  const leftIcon = getItemFromArrayBasedOnId(
    field.component,
    [...allAvailableFieldTypes, ...multiSelectFieldType, ...tableFieldType],
    { icon: null },
    'component'
  ).icon;
  switch (field.component) {
    case 'field-text': {
      return {
        ...defaultProps,
        'min-height': 40,
        'min-rows': 1,
        'icon-left': leftIcon,
      };
    }
    case 'field-date':
    case 'field-rich-text': {
      return {
        ...defaultProps,
      };
    }
    case 'field-number': {
      return {
        ...defaultProps,
        withDecimals: true,
        'icon-left': leftIcon,
        'size': 'block',
      };
    }
    case 'field-multi-select':
    case 'field-list': {
      return {
        ...defaultProps,
        options: field.options,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
      };
    }
    case 'field-table': {
      return {
        ...defaultProps,
        options: field.options,
        nullable: true,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
        editForm: false,
        editContent: false,
      };
    }
    case 'field-document':
    case 'field-public-document': {
      defaultProps.modelValue = getKey(field, 'meta', null);
      return {
        ...defaultProps,
        model: 'Performance',
        modelId: performanceId,
        canEditImage: false,
        useUploadModal: false,
        'emit-document-action-as': 'blur',
      };
    }
    default: {
      return defaultProps;
    }
  }
};
</script>
<template>
  <div>
    <ContentList
      :loading="fetchingData"
      title="Performances"
      :with-back-button="false">
      <div
        v-if="!fetchingData"
        class="h-full overflow-auto pt-edge">
        <EmptyStateFullPage
          v-if="!fetchingData && performances.length === 0"
          icon="fa-music fa-regular"
          description="No Performances Added"
          :button-function="null" />

        <ContentContainer
          v-for="performance in performances"
          :start-open="performances.length === 1"
          :title="performance.name">
          <template #content>
            <div
              class="h-full"
              :class="{ 'flex': performance.itinerary !== null }">
              <div class="flex-1 space-y-edge overflow-auto p-edge">
                <BoxContainer
                  v-if="performance.contacts"
                  header-size="h3"
                  openable
                  title="Contacts">
                  <div class="grid grid-cols-[repeat(auto-fit,minmax(250px,350px))] gap-edge">
                    <div
                      v-for="contact in performance.contacts"
                      class="group flex items-center gap-edge rounded border p-edge">
                      <NameAvatar
                        :full-name="concatName(contact)"
                        background-color="bg-[hsl(var(--color-event-type-orange))]"
                        class="h-[50px] text-2xl text-[hsl(var(--gray-950))]" />
                      <div class="flex flex-1 flex-col overflow-hidden">
                        <span class="text-sm text-soft">{{ contact.title }}</span>
                        <div class="flex items-center gap-edge-1/4">
                          <span :title="concatName(contact)">{{ concatName(contact) }}</span>
                        </div>
                        <DisplayPhone
                          v-if="contact.phone"
                          :country-code="contact.country_code"
                          :phone-number="contact.phone" />
                        <DisplayEmail
                          v-if="contact.email"
                          :email="contact.email" />
                      </div>
                    </div>
                  </div>
                </BoxContainer>
                <BoxContainer
                  v-for="section in performance.performance_info_sections"
                  header-size="h3"
                  openable
                  :title="section.title">
                  <GridTemplate
                    v-slot="{ item: field }"
                    gap="edge"
                    :model-value="section.performance_info_fields"
                    :columns="4">
                    <div
                      class="group flex h-full"
                      style="width: inherit">
                      <div
                        class="flex h-full flex-1 flex-col"
                        style="width: inherit">
                        <div>
                          <div class="gap-3 flex flex-1 truncate">
                            <InputLabel
                              :label="field.title"
                              :title="field.description" />
                          </div>
                        </div>
                        <div class="h-full min-h-[40px] w-full flex-1 items-center">
                          <component
                            :is="getComponent(field.component)"
                            v-bind="getProps(field, performance.id)"
                            @document-uploaded="documentUploaded(performance.id, section.id, field.id, $event)"
                            @blur="assignValue($event, performance.id, field, section.id)" />
                        </div>
                      </div>
                    </div>
                  </GridTemplate>
                </BoxContainer>
              </div>
              <div
                v-if="performance.itinerary"
                class="flex h-full w-[250px] flex-col overflow-hidden border-l bg">
                <h3 class="px-edge py-edge-1/2">Itinerary</h3>
                <div v-if="Object.keys(performanceItinerary(performance)).length === 0">
                  <EmptyStateFullPage
                    icon="fa-list"
                    size="small"
                    description="No itinerary Items Added Yet"
                    :button-function="null" />
                </div>
                <div
                  v-else
                  class="flex-1 divide-y overflow-auto pb-edge">
                  <div
                    v-for="(date, idx) in performanceItinerary(performance)"
                    :key="idx">
                    <div class="px-edge py-edge-1/2">
                      <InputLabel
                        super-text
                        :label="formatStampAsHumanReadableDate(date[0].start, humanReadableWeekDay)" />

                      <h4>{{ formatStampAsHumanReadableDate(date[0].start, 'Do [of] MMMM') }}</h4>
                    </div>
                    <div>
                      <div
                        v-for="(item, _idx) in date"
                        :key="_idx"
                        class="gap-x-2 grid min-h-[4rem] grid-cols-[40px_auto] items-center text-soft hover:bg-row hover:text">
                        <div class="text-center">
                          <i
                            class="fa fa-fw fa-regular"
                            :class="getItemIcon(item)" />
                        </div>
                        <div>
                          <div class="flex flex-col">
                            <InputLabel
                              super-text
                              :label="formattedStartAndEndAsInterval(item.start, item.end, date[0].start)" />

                            <div class="text-sm text">
                              {{ item.title }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ContentContainer>
      </div>
    </ContentList>
  </div>
</template>
